import * as React from "react";
import Layout from "../components/reusable/Layout";
import { Link, graphql } from "gatsby";
import { useEffect } from "react";
import Seo from "../components/reusable/Seo";
import { useTranslation } from "gatsby-plugin-react-i18next";
import {
  CompaniesSection,
  ContactSection,
  CostumerSection,
  HeroSection,
  ImpactSection,
  ResultsSection,
  SolutionsSection,
  TalentSection,
} from "../styles/pages/home";
import { Bg, Text } from "../components/ui";
import { Button, Stack, Box, Grid } from "@mui/material";
import cert from "../images/home/cert-b.png";
import forbes from "../images/home/forbes-30.png";
import Carousel from "../components/reusable/Carousel";
import CarouselTestimonials from "../components/reusable/CarouselTestimonials";

const logoOne =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2Flogo-1.png?alt=media&token=9c736c96-2da0-4366-89a6-a1d745b0921d";
const logoTwo =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2Flogo-2.png?alt=media&token=3f8880c8-1b24-4840-9b9e-6ffff200ac38";
const logoThree =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2Flogo-3.png?alt=media&token=d627ac40-ba19-40fa-b507-573ab8fad721";
const logoFour =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2Flogo-4.png?alt=media&token=6454d413-318a-44a8-b3dd-99542c8ab241";
const logoFive =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2Flogo-5.png?alt=media&token=60b8147c-7ccf-4304-a53b-572992bd6230";
const logoSix =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2Flogo-6.png?alt=media&token=89855dad-92fd-4697-a9e2-985694482876";
const logoSeven =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2F3.png?alt=media&token=960fe209-b39a-460b-a553-b07bb5e6e5fe";
const logoEight =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2Flogo-8.png?alt=media&token=2423ff8b-4052-40bf-a425-ec29b18e0f52";
const logoNine =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2F2.png?alt=media&token=c3cdbb9b-e9be-493d-a841-0a865f26dffd";

const grupo =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fhome%2FGrupo%2026767.png?alt=media&token=a376f89d-5c80-449d-90da-ff619d800755";
const pieza =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fhome%2F%2B350%2C000%20personas%20registradas.png?alt=media&token=07588d58-84fa-4aef-a935-42afc769d36c";
const time =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fhome%2FTalento%20en%20tiempo%20re%CC%81cord.png?alt=media&token=8a76431f-244f-4144-bdd6-179813371f4a";
const lugar =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fhome%2FTodo%20desde%20un%20solo%20lugar.png?alt=media&token=45271423-c588-4df0-ad95-ab43a9f70321";
const imageATS =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fhome%2FApplicant%20Tracking%20System.png?alt=media&token=68b8b23b-c6cf-4711-a16e-ce8c3a7f48e3";
const headhunting =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fhome%2FHeadhunting.png?alt=media&token=9499700e-4e5d-4551-aa16-44d3bb832203";
const solutions =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fhome%2F%C2%BFAu%CC%81n%20no%20sabes%20que%CC%81%20solucio%CC%81n%20te%20conviene%20ma%CC%81s_.png?alt=media&token=1c1ac53c-6624-4644-886a-38d1b7b16b9e";
const impact =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fhome%2FNos%20mueve%20el%20impacto%201.png?alt=media&token=66b27e7a-c563-40cf-a383-c5919cbdebf1";
const carbonNeutral =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fhome%2Fwe%20are%20carbon%20neutral.png?alt=media&token=d480d57f-bfe1-4bdf-9b60-1d34e762ac43";
const impact2 =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fhome%2FNos%20mueve%20el%20impacto%202.png?alt=media&token=134e8db0-ed7e-44a0-9e05-155ed45a6ee2";

const rpoImage =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fexecutive-search%2FrpoHome.png?alt=media&token=ed936b96-d7c9-443a-a0f0-6cfbab9e083a";
const executiveSearch =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fexecutive-search%2Fexecutive-search-home.png?alt=media&token=b4e8d3b3-3f5e-4f69-b95c-9a700493503d";

const App = () => {
  const { t } = useTranslation("home");

  const items = React.useMemo(
    () => [
      {
        subtitle: t("servicesItemSubtitle2"),
        title: t("servicesItemTitle2"),
        description: t("servicesItemDescription2"),
        image: headhunting,
        link: "/headhunting",
      },
      {
        subtitle: t("servicesItemSubtitle1"),
        title: t("servicesItemTitle1"),
        description: t("servicesItemDescription1"),
        image: imageATS,
        link: "/ats",
      },
      {
        subtitle: t("servicesItemSubtitle3"),
        title: t("servicesItemTitle3"),
        description: t("servicesItemDescription3"),
        image: rpoImage,
        link: "/rpo",
      },
      {
        subtitle: t("servicesItemSubtitle4"),
        title: t("servicesItemTitle4"),
        description: t("servicesItemDescription4"),
        image: executiveSearch,
        link: "/executive-search",
      },
    ],
    [t]
  );

  // Redirects if user comes from Google integration in ATS
  useEffect(() => {
    const integrationUrl = window.location.href;
    if (window.location.href.includes("www.googleapis.com")) {
      const atsUrl = integrationUrl.replace(
        "https://empresas.lapieza.io/",
        "https://ats.lapieza.io/"
      );
      window.open(atsUrl, "_self");
    }
  }, []);

  return (
    <Layout modeHeader="dark">
      <Seo
        canonical="https://empresas.lapieza.io"
        title="Empresas LaPieza - Líderes de reclutamiento y headhunting"
        image="https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fpreviews%2FLANDING%20EMPRESAS.png?alt=media&token=3dd56d07-4544-4eef-ab5c-5b53a475137e"
      />
      <Bg.Gradient>
        <HeroSection className="hero-section">
          <div className="hero-section main-landing-container-row">
            <article>
              <Text.Title
                text={t("heroTitle1")}
                fontSize="42px"
                textAlign="start"
              />
              <Text.Title fontSize="42px" textAlign="start">
                <span
                  style={{
                    background: "#F7AD19",
                    color: "#fff",
                    borderRadius: "22px",
                    padding: "0 10px",
                    wordWrap: "break-word",
                    whiteSpace: "normal",
                    width: "100%",
                  }}
                >
                  {t("heroTitle2")}
                </span>
              </Text.Title>
              <Text.Title
                text={t("heroTitle3")}
                fontSize="42px"
                textAlign="start"
              />
              <Text.Body
                text={t("heroDesc")}
                sx={{
                  fontSize: "18px",
                  textAlign: "start",
                  marginTop: "20px",
                  display: "block",
                }}
              />

              <Stack direction="row" spacing={2} mt={3}>
                <Button
                  href="https://ats.lapieza.io/register"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    width: "150px",
                    fontSize: "16px",
                    mb: 5,
                  }}
                >
                  <Text.Body
                    text={t("moreInformation")}
                    sx={{
                      color: "primary.white",
                      fontSize: "16px",
                    }}
                  />
                </Button>
                <Button
                  href="https://api.whatsapp.com/send/?phone=526221635997&text=%C2%A1Hola%20Alexia!%20Me%20interesa%20registrarme%20y%20saber%20m%C3%A1s%20sobre%20los%20servicios%20de%20LaPieza"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    width: "280px",
                    fontSize: "16px",
                    mb: 5,
                    bgcolor: "primary.white",
                    "&:hover": {
                      filter: "brightness(0.9)",
                      animation: "ease 1s infinite",
                      bgcolor: "primary.white",
                    },
                  }}
                >
                  <Text.Body
                    text={t("requestMoreInformation")}
                    sx={{
                      color: "primary.main",
                      fontSize: "16px",
                    }}
                  />
                </Button>
              </Stack>
            </article>
            <figure className="hero-image">
              <img src={grupo} alt="LaPieza" />
            </figure>
          </div>
        </HeroSection>
      </Bg.Gradient>
      <CompaniesSection>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="center"
          spacing={1}
          mb={4}
        >
          <Text.Title sx={{ fontSize: "66px" }}>+500</Text.Title>
          <Box sx={{ maxWidth: "400px", marginBottom: "50px" }}>
            <Text.Title text={t("infoFooterTitle")} fontSize={"38px"} />
          </Box>
        </Stack>
        <div className="companies-section">
          <Carousel logos={[logoOne, logoTwo, logoThree, logoFour, logoFive]} />
          <Carousel logos={[logoSix, logoSeven, logoEight, logoNine]} />
        </div>
      </CompaniesSection>
      <Stack alignItems="center" mb={10}>
        <Text.Title
          sx={{
            maxWidth: "500px",
          }}
          text={t("servicesTitle")}
          fontSize="42px"
        />
        <Grid
          container
          spacing={2}
          padding={{
            xs: 2,
            md: "20px 220px",
          }}
        >
          {items?.map((item) => (
            <Grid item sx={12} md={6} padding={1}>
              <Bg.Linear>
                <Stack
                  position="relative"
                  height="100%"
                  justifyContent="space-between"
                >
                  <Stack padding="40px 30px">
                    <Text.SubTitle textAlign="start">
                      {item.subtitle}
                    </Text.SubTitle>
                    <Text.Title
                      sx={{
                        maxWidth: "450px",
                        height: {
                          md: "80px",
                          sm: "130px",
                        },
                      }}
                      fontSize="28px"
                      textAlign="start"
                    >
                      {item?.title}
                    </Text.Title>
                    <Text.Body
                      sx={{
                        maxWidth: "400px",
                        mt: 4,
                      }}
                      textAlign="start"
                      fontSize="15px"
                    >
                      {item?.description}
                    </Text.Body>
                  </Stack>
                  <Stack
                    alignItems="end"
                    direction="row"
                    justifyContent="end"
                    height="100px"
                  >
                    <Stack
                      padding="0 0 20px 30px"
                      alignItems="start"
                      width="100%"
                    >
                      <Link
                        to={item.link}
                        style={{
                          textDecoration: "none",
                          width: "100px",
                        }}
                      >
                        <Text.SubTitle
                          text={t("seeMoreButton")}
                          color="primary.orange"
                          fontSize="16px"
                        />
                      </Link>
                    </Stack>
                    <Stack
                      alignItems="end"
                      position="absolut"
                      justifyContent="end"
                    >
                      <img
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                        }}
                        src={item.image}
                        alt={item?.title}
                      />
                    </Stack>
                  </Stack>
                </Stack>
              </Bg.Linear>
            </Grid>
          ))}
        </Grid>
      </Stack>
      <Bg.Gradient>
        <SolutionsSection>
          <div className="main-landing-container-row">
            <Box>
              <article>
                <Box>
                  <Text.Title
                    sx={{
                      display: "block",
                      marginTop: "20px",
                      marginBottom: "30px",
                      fontSize: "38px",
                      textAlign: "start",
                    }}
                    text={t("solutionsTitle")}
                  />
                  <Text.Body
                    text={t("solutionsDescription")}
                    fontSize={"18px"}
                  />
                </Box>
              </article>
              <Button
                href="https://api.whatsapp.com/send/?phone=526221635997&text=%C2%A1Hola%20Alexia!%20Me%20interesa%20registrarme%20y%20saber%20m%C3%A1s%20sobre%20los%20servicios%20de%20LaPieza"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  width: "280px",
                  fontSize: "16px",
                  mb: 5,
                  marginTop: "30px",
                }}
              >
                <Text.Body
                  text={t("moreInformation")}
                  sx={{
                    color: "primary.white",
                    fontSize: "16px",
                    textAlign: "start",
                  }}
                />
              </Button>
            </Box>
            <figure>
              <img src={solutions} alt="Servicios LaPieza" />
            </figure>
          </div>
        </SolutionsSection>
      </Bg.Gradient>
      <ImpactSection>
        <div className="main-landing-container-column">
          <Box
            sx={{
              maxWidth: "457px",
              marginBottom: "70px",
              textAlign: "center",
            }}
          >
            <Text.Title
              text={t("impactTitle")}
              fontSize={"38px"}
              sx={{ display: "block", marginBottom: "20px" }}
            />
            <Text.Body text={t("impactDescription")} fontSize={"18px"} />
          </Box>
          <div className="impact-section">
            <img className="figure-one" src={impact} alt="Servicios LaPieza" />
            <figure className="cert-impact">
              <img className="figure-two" src={cert} alt="Empresa B LaPieza" />
              <img
                className="figure-carbon"
                src={carbonNeutral}
                alt="Empresa B LaPieza"
              />
            </figure>
            <img
              className="figure-three"
              src={impact2}
              alt="Carbon Neutral LaPieza"
            />
          </div>
        </div>
      </ImpactSection>
      <ResultsSection>
        <div className="main-landing-container-column">
          <article className="main-title">
            <Text.Title text={t("resultsTitle")} fontSize={"38px"} />
            <figure>
              <img
                src={forbes}
                alt="Forbes 30 promeas de los negocios 2022 LaPieza"
              />
            </figure>
          </article>
          <TalentSection>
            <Stack alignItems="center">
              <Stack
                className="info-list"
                direction={{ xs: "column", md: "row" }}
                marginTop="20px"
                justifyContent="space-between"
                alignItems="start"
                spacing={5}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  gap={2}
                  marginBottom={"40px"}
                >
                  <img
                    src={pieza}
                    alt="Ilustración de LaPieza"
                    style={{
                      width: "100px",
                      height: "auto",
                      alignSelf: "center",
                      marginBottom: "20px",
                    }}
                  />
                  <Text.Title
                    text={t("talentItemTitle1")}
                    fontSize="18px"
                    textAlign="start"
                  />
                  <Text.Body
                    text={t("talentItemDescription1")}
                    fontSize="16px"
                    textAlign="start"
                  />
                </Box>

                <Box display="flex" flexDirection="column" gap={2}>
                  <img
                    src={time}
                    alt="Ilustración de LaPieza"
                    style={{
                      width: "140px",
                      height: "auto",
                      alignSelf: "center",
                      marginBottom: "20px",
                    }}
                  />
                  <Text.Title
                    text={t("talentItemTitle2")}
                    fontSize="18px"
                    textAlign="start"
                  />
                  <Text.Body
                    text={t("talentItemDescription2")}
                    fontSize="16px"
                    textAlign="start"
                  />
                </Box>

                <Box display="flex" flexDirection="column" gap={2}>
                  <img
                    src={lugar}
                    alt="Ilustración de LaPieza"
                    style={{
                      width: "132px",
                      height: "auto",
                      alignSelf: "center",
                      marginBottom: "20px",
                    }}
                  />
                  <Text.Title
                    text={t("talentItemTitle3")}
                    fontSize="18px"
                    textAlign="start"
                  />
                  <Text.Body
                    text={t("talentItemDescription3")}
                    fontSize="16px"
                    textAlign="start"
                  />
                </Box>
              </Stack>
            </Stack>
          </TalentSection>
        </div>
      </ResultsSection>
      <Bg.Linear>
        <CostumerSection>
          <Stack>
            <Text.Title
              text={t("costumerTitle")}
              fontSize={"38px"}
              textAlign="start"
              sx={{ marginBottom: "50px" }}
            />
            <CarouselTestimonials />
          </Stack>
        </CostumerSection>
      </Bg.Linear>
      <ContactSection>
        <div className="main-landing-container-column">
          <Stack sx={{ maxWidth: "655px", marginBottom: "80px" }}>
            <Text.Title text={t("contactTitle")} fontSize={"38px"} />
          </Stack>
          <div className="button-group">
            <Button
              href="https://ats.lapieza.io/register"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                width: "280px",
                fontSize: "16px",
                mb: 5,
              }}
            >
              <Text.Body
                text={t("moreInformation")}
                sx={{
                  color: "primary.white",
                  textAlign: "center",
                }}
              />
            </Button>
            <Link className="see-more-button" to="/contact">
              <Text.Body text={t("teamContact")} fontSize={"16px"} />
            </Link>
          </div>
        </div>
      </ContactSection>
    </Layout>
  );
};

export default App;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
